exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-mdx-projects-project-1-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/NetSpace/NetSpace/mdx/projects/project1/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-mdx-projects-project-1-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-mdx-projects-project-2-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/runner/work/NetSpace/NetSpace/mdx/projects/project2/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-tsx-content-file-path-mdx-projects-project-2-index-mdx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */)
}

